
import { System } from '@pureweb/platform-sdk-react';

export const useIsIphone = () => {
    function isIpadOS() {
        return navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 2 &&
          /MacIntel/.test(navigator.platform);
      }

    return System.Browser().os === "iOS" || window.navigator.userAgent.includes("iPad") || isIpadOS()
}