const RotateScreen = () => {
  return (
    <div id="turn">
      <div className="inner">
        Please rotate your device or resize your browser. <br />
        <img width="100px" height="100px" src="/rotate-smartphone.png" alt="rotate screen" />
      </div>
    </div>
  );
};

export default RotateScreen;
