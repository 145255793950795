import { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

interface NavbarProps {
  isAuthenticated: boolean;
}

const Navbar = (props: NavbarProps) => {
  if (props.isAuthenticated) {
    return (
      <ProfileButton />
    );
  }
  return <></>;
};

const ProfileButton = () => {
  const menuRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);
  const { user, isAuthenticated, logout } = useAuth0();

  useEffect(() => {
    const closeOpenMenus = (e: any) => {
      if (menuRef.current && isOpen && !menuRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", closeOpenMenus);
    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  });

  if (!user) {
    return null;
  }

  const toggleSubMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="fixed top-16 right-16 md:top-26 md:right-26 z-1">
      <div className="mb-5">
        <button type="button" className="relative" id="menu-button" onClick={toggleSubMenu}>
          {isAuthenticated ? (
            <img
              className={`w-58 h-58 rounded cursor-pointer border-4 border-transparent hover:border-gray-200 transition duration-300 ease-in-out hover:shadow-lg${
                isOpen ? " shadow-lg border-gray-200" : ""
              }`}
              src={user.picture}
              alt={user.name}
              referrerPolicy="no-referrer"
            />
          ) : (
            <span className="text-gray-700 font-bold text-lg">{user.name}</span>
          )}
        </button>
      </div>

      {isOpen && (
        <div
          id="profile-menu"
          className="absolute top-full right-0 w-56 rounded-10 shadow-lg bg-gray-dark text-white hover:bg-gray transition duration-300 ease-in-out"
        >
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="menu-button">
            <button ref={menuRef} className="block px-30 py-6 text-sm" onClick={handleLogout}>
              Logout
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
