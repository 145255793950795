import {
    LaunchStatusEvent,
    LaunchStatusType,
    StreamerStatus
  } from '@pureweb/platform-sdk';


export const useServiceStatus = (launchRequestStatus: LaunchStatusEvent, streamerStatus: StreamerStatus) => {


    const serviceUnavailable = (): Boolean => {
        return (launchRequestStatus.status === LaunchStatusType.Unavailable ||
        launchRequestStatus.status === LaunchStatusType.Error ||
        streamerStatus === StreamerStatus.Failed)
    }
    
    const serviceNotSupported = (): Boolean => {
        return streamerStatus === StreamerStatus.NotSupported
    }

    return { serviceUnavailable, serviceNotSupported }
}