export enum APPSTATES {
    LOGIN = 0,
    MICCHECK = 1,
    MICCHECKCOMPLETE = 2,
    LOADING = 3
};

export enum MICPERMISSIONSTATES {
    UNSET = -1,
    NO_MICROPHONE_INPUT = 0,
    TESTING_MICROPHONE = 1,
    DENIED = 2,
    ALLOWED = 3,
}
export enum AUDIOTESTSTATES {
    NOT_STARTED = -1,
    BELOW_BANDWITH = 0,
    RUNNING = 1,
    COMPLETE = 2,
    FAILURE = 3,
    WITHOUT_MICROPHONE = 4,
    TEST_BANDWIDTH = 5,
    NO_MICROPHONE_INPUT = 6,
    STOPPED = 7
}