import { useEffect, useRef, useState } from "react";
import { Input } from "semantic-ui-react";
import { InputEmitter, StreamerStatus } from "@pureweb/platform-sdk";
import { Subject } from "rxjs";
import { APPSTATES } from "./constants/states";
import { appStatusService } from "./services/appStatusService";
import Checkbox from "./components/Checkbox";
import ScrollViewWrapper from "./ScrollViewWrapper";
import Auth0Form from "./Auth0Form";
import LocalAuth from "./helpers/LocalAuth";
import LocalAuthForm from "./LocalAuthForm";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticationState } from "./hooks/useAuthenticationSwitch";
import { Status } from "./hooks/useGrayconTickets";
import useClientTranslation from "./hooks/useClientTranslation";
import { useConverveAuthentication } from "./hooks/useConverveAuthentication";
import useLocalAuth from "./hooks/useLocalAuth";
import usePlatformLanguage from "./hooks/usePlatformLanguage";
import { useLocation } from 'react-router-dom';
import { useAzureAuthentication } from "./hooks/useAzureAuthentication";
import { useMsalAuthentication } from '@azure/msal-react';
import {  InteractionType } from '@azure/msal-browser';

const VideoBackground = () => {
  return (
    <video autoPlay loop muted className="BGVideo">
       <source src={'https://ueberverse.s3.eu-central-1.amazonaws.com/bg.mp4'} type="video/mp4" />
    </video>
  );
};


interface AuthenticationViewProps {
  check: (code: string) => any;
  status: Status;
  emitter: InputEmitter;
  streamerStatus: StreamerStatus;
  messageSubject: Subject<string>;
  authenticationState: AuthenticationState;
  isAuthenticated: Boolean;
  localAuth: LocalAuth | undefined;
  logout: () => void;
  shouldAuthenticate: boolean;
  triggerAzureAuthentication: () => void;
}

const MsalAuthenticationComponent = ({ enabled }) => {
  useMsalAuthentication(InteractionType.Redirect, enabled);
  return null; // This component doesn't need to render anything
};


export default function AuthenticationView(props: AuthenticationViewProps) {
  const { language } = usePlatformLanguage();
  const { isTranslationSet, translation } = useClientTranslation(language);
  const [isViewUpdated, setIsViewUpdated] = useState<boolean>(false);
  const launchContainer = useRef<HTMLDivElement>(null);
  const { user } = useAuth0();
  const namespace = "https://metaverse.bizzlogic.com/";
  const userMetadata = user?.[namespace + "user_metadata"];
  console.log(user);
  const [username, setUsername] = useState<string | null | undefined>();
  const [userRole, setUserRole] = useState<string | null | undefined>();
  const [shouldAuthenticate, setShouldAuthenticate] = useState(false);
  
  const { azureUserClaims, loadingAzureClaims, logoutAzure } = useAzureAuthentication(true);

  

const triggerAzureAuthentication = () => {
  setShouldAuthenticate(true);
};


  const [showActions, setShowActions] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowActions(true);
    }, 4000);
  }, []);


  const updateIsViewUpdated = (state: boolean) => {
    setIsViewUpdated(state);
  };

  useEffect(() => {
    if (loadingAzureClaims) {
      // Optionally, handle the loading state here, such as showing a spinner
      return;
    }
    const getUsername = () => {
      if (!props.isAuthenticated) return;

      switch (props.authenticationState) {
        case AuthenticationState.AUTH0:
          return user ? userMetadata?.Name : null;
        case AuthenticationState.LOCAL:
          return props.localAuth?.name;
        case AuthenticationState.AZURE:
          console.log("we are in azure");
          return azureUserClaims?.name;
        default:
          return;
      }
    };
    setUsername(getUsername);
    const getUserRole = () => {
      if (!props.isAuthenticated) return;

      switch (props.authenticationState) {
        case AuthenticationState.AUTH0:
          return user ? userMetadata?.Name : null;
        case AuthenticationState.LOCAL:
          return props.localAuth?.name;
        case AuthenticationState.AZURE:
          console.log("we are in azure");
          return azureUserClaims?.role;
        default:
          return;
      }
    };
    setUserRole(getUserRole);
  }, [props.authenticationState, props.isAuthenticated, props.localAuth, azureUserClaims, loadingAzureClaims]);

  useEffect(() => {
    const loadHandler = () => {
      if (launchContainer?.current && isTranslationSet) {
        if (launchContainer.current) {
          launchContainer.current.style.opacity = "1";
        }
      }
    };
  
    window.addEventListener("load", loadHandler);
    return () => {
      document.removeEventListener("load", loadHandler);
    };
  }, [isTranslationSet]);
  

  const Actions = () => {
    return (
      <div className={`flex justify-center items-center w-full`}>
       
       
        <input
          onClick={() => appStatusService.updateAppStatus(APPSTATES.MICCHECK)}
          className={`btn btn-brown flex-1${props.authenticationState === AuthenticationState.LOCAL ? "" : " md:max-w-1/2"}`}
          type="button"
          placeholder={translation.launch.actions.login}
          value={translation.launch.actions.continueButton}
        />
         
     {props.authenticationState === AuthenticationState.AZURE && (
        <button onClick={() => props.logout()} style={{
          position: 'fixed',
          bottom: '20px', // Adjust as needed
          right: '50px',
          transform: 'translateX(-50%)',
          zIndex: 1000
          // Further styling here
        }}>{translation.launch.actions.logout}</button>
      )}</div>
    );
  };

  const Loading = () => {
    return <h3 className="md:text-2xl xxl:4xl">{translation.AuthenticationView.loading}</h3>;
  };

  const Headline = () => {
    return <h1 className="md:text-2xl xxl:4xl">{translation.AuthenticationView.title}</h1>;
  };

  const IntroImage = () => {
    return (
      <div className="h-32 md:h-auto w-53 hidden md:block">
        <img className="object-cover w-full h-screen" src="/intro.jpg" alt="img" />
      </div>
    );
  };

  const Logo = () => {
    return <img alt="Bizzlogic Logo" src="/LOGO_WHITE.png" className=" mx-auto" style={{ marginBottom: "27px", width: "150px" }} />;
  };
  const [showImprint, setShowImprint] = useState(false);

  const toggleImprint = () => {
    setShowImprint(!showImprint);
  };

  const ImprintDiv = () => {
    if (!showImprint) return null;
    return (
      <button
      onClick={toggleImprint}
      className="imprint-container"
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 'auto', // Adjust as needed
        maxWidth: '80%', // Prevents the div from being too wide on large screens
        backgroundColor: 'rgba(255,255,255,0.9)',
        zIndex: 1000,
        padding: '50px',
        borderRadius: '0',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        textAlign: 'left', // Align text to the left
        overflowY: 'auto', // Allows scrolling if the content is too long
        maxHeight: '80vh', // Limit the height, making it scrollable
        cursor: 'default' // Default cursor on the button
      }}
    >
      <div className="imprint-text"  style={{color: 'black', textAlign: 'center'}}>
      <h2><strong>Imprint</strong></h2><br />
      <strong>Plan.Net Studios GmbH & Co. KG</strong><br />
      House of Communication<br />
      Friedenstr. 24<br />
      81671 München<br />
      Deutschland<br />
      Tel.: +49 89 2050 30<br />
      Fax: +49 89 2050 3611<br />
      E-Mail: info@plan-net.com<br />
      USt-ID Nummer: beantragt<br />
      Amtsgericht München: HRA 118456<br />
      Vertretungsberechtigte Geschäftsführer:<br />
      Sebastian Küpers, Wolf Ingomar Faecks, Dr. Ingo Stein<br />
      Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br />
      Florian Haller, Friedenstr. 24, 81671 München<br />
      <br />
      Technischer Service Provider: Bizzlogic GmbH</div>
      <div
    style={{
      position: 'absolute',
      color: "black",
      fontWeight: 'bold',
      top: '10px', // Adjust as needed
      right: '10px', // Adjust as needed
      cursor: 'pointer' // Changes cursor to pointer when hovering over the close icon
    }}
  >
    X
  </div>
    </button>
    
    );
  };

  const LogoTopRight = () => {
    return (
      <img
        src="/serviceplan.png" // Replace with your logo's path
        alt="Logo"
        style={{
          position: 'absolute',
          top: '30px', // Adjust as needed
          right: '30px', // Adjust as needed
          width: '250px' // Adjust as needed
        }}
      />
    );
  };

  const Subtitle = () => {
    if (props.isAuthenticated) {
      return <h2>{translation.launch.subtitle}</h2>;
    } else {
      return (
        <h2 className="text-center md:text-left" style={{ marginTop: "5px", marginBottom: "35px" }}>
          {props.authenticationState === AuthenticationState.AUTH0 ? translation.launch.subtitle : translation.launch.activate}
        </h2>
      );
    }
  };

  const Welcome = () => {
    return (
      <h2 className="mt-10 text-center">
        {translation.AuthenticationView.welcome} {username + "!"} {"You are a " + userRole + "!"}
      </h2>
    );
  };
  console.log("azure state",props.isAuthenticated);
  const AuthError = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const error = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');

    if (error === 'unauthorized' && errorDescription) {
      setErrorMessage(decodeURIComponent(errorDescription));
    }
  }, [location]);

  return (
    <div>
      {errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );

  };

    return (
 
      <div id="launchContainer" ref={launchContainer} className="transition duration-1000 bg-fullscreen-bg bg-cover bg-center bg-no-repeat bg-fixed ">
           <VideoBackground />
           <LogoTopRight />
           <ImprintDiv /> {/* Imprint Div */}
      <button
        onClick={toggleImprint}
        style={{
          position: 'fixed',
          bottom: '20px', // Adjust as needed
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 1000
          // Further styling here
        }}
      >
        Imprint
      </button>
      <div className="flex flex-col md:flex-row w-full min-h-screen md:h-screen items-start justify-center">
          <ScrollViewWrapper {...props} isViewUpdated={isViewUpdated} outerStyles={"w-full"} innerStyles={"w-full"}>
          <div className="relative flex flex-col mt-100 items-center mx-auto z-0 w-90 md:w-80" style={{ maxWidth: "669px" }}>
              <Logo />
              <AuthError />
             
              {!props.isAuthenticated ? (
                <AuthenticationForm {...props} isViewUpdated={isViewUpdated} updateIsViewUpdated={updateIsViewUpdated} shouldAuthenticate={shouldAuthenticate}
                triggerAzureAuthentication={triggerAzureAuthentication} />
              ) : (
                ( props.authenticationState === AuthenticationState.AZURE) && (
                  <>
                    <Welcome />
                    {showActions ? <Actions /> : <Loading />}
                  </>
                )
              )}
            </div>
          </ScrollViewWrapper>
          {/* IntroImage component call removed */}
        </div>
      </div>
    );
}


const AuthenticationForm = (props:any) => {
  const [code, setCode] = useState<string>("");
  const { language } = usePlatformLanguage();
  const { translation } = useClientTranslation(language);
  const { isAuthenticated, code: converveToken } = useConverveAuthentication(props.authenticationState === AuthenticationState.CONVERVE);
  const { authenticate } = useLocalAuth();

  useEffect(() => {
    if (props.authenticationState !== AuthenticationState.GC || props.status !== Status.OK) return;
  }, [props.status, props.authenticationState]);

  useEffect(() => {
    if (props.authenticationState !== AuthenticationState.CONVERVE) return;

    setCode(converveToken);
  }, [converveToken, isAuthenticated, props.authenticationState]);

  const localAuthSave = (name: string, job: string, occupation: string, interests: string, country: string, companyName: string, email: string) => {
    authenticate(name, job, occupation, interests, country, companyName, email);
    appStatusService.updateAppStatus(APPSTATES.MICCHECK);
  };

  switch (props.authenticationState) {
      case AuthenticationState.AZURE:
        // When the Azure auth should occur, render the MsalAuthenticationComponent
        return props.shouldAuthenticate ? (
          <MsalAuthenticationComponent enabled={props.shouldAuthenticate} />
        ) : (
          // Provide a button or another way to set `shouldAuthenticate` to `true`
          <input
          onClick={props.triggerAzureAuthentication}
          className={`btn btn-brown`}
          type="button"
          placeholder={"Login now"}
          value={"Login now"}
        />
        
        );

    case AuthenticationState.AUTH0:
      return <Auth0Form {...props} />;
    case AuthenticationState.CONVERVE:
      return (
        <div id="launchContainer">
          <div id="container">
            <h1>{translation.AuthenticationView.converve.title}</h1>
          </div>
          <div id="codecontainer">
            {!Boolean(code) && <h3 style={{ marginTop: 20 }}>{translation.AuthenticationView.converveNoCode}</h3>}
            {!isAuthenticated() && <h3 style={{ marginTop: 20 }}>{translation.AuthenticationView.converveWrongCode}</h3>}
          </div>
          <br />
          <br />
        </div>
      );
    case AuthenticationState.GC:
      return (
        <div id="launchContainer">
          <div id="container">
            <h1>{translation.AuthenticationView.gc.title}</h1>
          </div>
          <div id="codecontainer">
            <h1>{translation.AuthenticationView.logintitle}</h1>
            <h3>{translation.AuthenticationView.enterCode}</h3>
            <Input type="text" placeholder="Code" onChange={(e) => setCode(e.target.value)} />
            <br />
            {props.status === Status.NOT_FOUND && <h3>{translation.AuthenticationView.noCodeFound}</h3>}
            {props.status === Status.OK && <h3>{translation.AuthenticationView.codeOk}</h3>}
            {/* { !props.loading && props.status !== Status.OK && <Button onClick={() => props.check(code)}>{ translation.AuthenticationView.button }</Button> } */}
            {props.status === Status.OK && code && <LocalAuthForm onSave={localAuthSave} code={code} />}
          </div>
          <br />
          <br />
        </div>
      );
    case AuthenticationState.GOOGLE:
      return (
        <div>
          <button className="btn text-darkblue pt-13 pb-13 focus:outline-brown">
            <img src="google.svg" alt="Google Logo" className="inline-block w-35 h-35 mr-20" />
            Login with Google
          </button>
          <div className="w-full flex items-center mt-20 mb-18">
            <div className="flex-grow h-1 bg-brown"></div>
            <span className="flex-shrink px-13 text-brown-dark">Or login with email</span>
            <div className="flex-grow h-1 bg-brown"></div>
          </div>

          <input type="email" placeholder="Email" name="email" className="btn" />
          <input type="password" placeholder="Password" name="pass" className="btn" />

          <div className="w-full flex flex-col md:flex-row justify-between mt-8 mb-20 space-y-20 md:space-y-0">
            <Checkbox>Keep me logged in</Checkbox>
            <Checkbox>Forgot your password?</Checkbox>
          </div>

          <input
            onClick={() => appStatusService.updateAppStatus(APPSTATES.MICCHECK)}
            className="btn btn-brown cursor-pointer"
            type="submit"
            placeholder={translation.launch.actions.login}
          />

          <div id="signup" className="text-center">
            <span className="text-gray-light">Haven’t sign up yet?</span>{" "}
            <span
              className="text-brown rounded hover:text-brown-dark focus:text-brown-dark focus:outline-brown-narrow"
              role="button"
              tabIndex={0}
              onClick={() => {
                props.updateIsViewUpdated(true);
              }}
              onKeyDown={() => {
                props.updateIsViewUpdated(true);
              }}
            >
              Sign up
            </span>
          </div>
        </div>
      );
    case AuthenticationState.LOCAL:
      return <LocalAuthForm onSave={localAuthSave} {...props} />;
    default:
      return <></>;
  }
};
