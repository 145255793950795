import { InputEmitter, StreamerStatus } from "@pureweb/platform-sdk";

import { useEffect } from "react";
import { Subject } from "rxjs";
import { useAuth0 } from "@auth0/auth0-react";

import { AuthenticationState } from "./useAuthenticationSwitch";

require("dotenv").config();

const VALUE_LISTENER = "Auth0Exchange";

export const useUnrealAuth0Exchange = (emitter: InputEmitter, streamerStatus: StreamerStatus, messageSubject: Subject<string>, state: AuthenticationState) => {
  const { user } = useAuth0();
  const namespace = "https://metaverse.bizzlogic.com/";
  const userMetadata = user?.[namespace + "user_metadata"];
  const appMetadata = user?.[namespace + "app_metadata"];
  
  useEffect(() => {
    const emitContent = (content: any) => {
      console.log('Auth0Exchange.tsx, send to unreal: ' + JSON.stringify(content));
      emitter.EmitUIInteraction(content);
    };
  
    const cannotEmit = (streamerStatus: StreamerStatus, emitter: InputEmitter): Boolean => {
      return !emitter || streamerStatus !== StreamerStatus.Connected;
    };
    
    console.log('Auth0Exchange.tsx, cannotEmit: ' + cannotEmit(streamerStatus, emitter));
    if (cannotEmit(streamerStatus, emitter)) return;


    const subscription = messageSubject.subscribe(
      (value: string) => {
        console.log('Auth0Exchange message subscription');
        try {
          let json = JSON.parse(value);
          if (json.type === VALUE_LISTENER) {
            console.log('Auth0Exchange.tsx, ' + userMetadata?.Name + user?.email + userMetadata?.Job + userMetadata?.Interest + userMetadata?.Country + userMetadata?.Company + userMetadata?.userLevel );
            emitContent({ 
              name: userMetadata?.Name, 
              email: user?.email,
              job: userMetadata?.Job,
              interests: userMetadata?.Interest,
              country: userMetadata?.Country,
              companyName: userMetadata?.Company,
              userLevel: appMetadata?.userLevel  });
          }
        } catch (e) {
          console.error(e);
        }
      },
      (err: any) => {
        console.error(err);
      }
    );
    

    return () => {
      subscription.unsubscribe();
    };

  }, [emitter, messageSubject, streamerStatus, user]);
};
