import { useEffect, useState } from "react";
import ConverveAuth from "../helpers/ConverveAuth";
import ConverveParticipant from "../helpers/ConverveParticipant";
import { useQuery } from "./useQuery";

export const useConverveAuthentication = (enabled: boolean) => {
  const params = useQuery();

  const [participant, setParticipant] = useState<ConverveParticipant | undefined>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);

  useEffect(() => {
    if (!enabled || !params || participant) return;
    const converve = new ConverveAuth(params!.code as string);

    setIsAuthenticated(converve.valid);
    setParticipant(converve.participant);
  }, [enabled, params, participant]);

  function logout() {
    setIsAuthenticated(false);
    setParticipant(undefined);
    ConverveAuth.cleanUp();
    window.location.reload();
  }

  return {
    isAuthenticated: () => isAuthenticated,
    code: params!.code as string,
    logout,
    participant: participant
  };
};
