import AudioTest from './webrtcTroubleshooter/diagnostics/AudioTest';
import PermissionsTest from './webrtcTroubleshooter/diagnostics/PermissionsTest';
import TestSuite from './webrtcTroubleshooter/utils/TestSuite'
require('dotenv').config()

export const ICE_SERVER_URL = 'https://pixelstreaming-services.pureweb.io/pixelstreaming/nts/config'

enum TestCase {
  microphonePermission = 0,
  audio = 1
}

export class WebRTCTroubleshooter {

  video = false
  audio = true
  private testSuite: TestSuite;
  statusFnc: Function

  constructor(status: Function) {
    this.testSuite = new TestSuite()
    this.statusFnc = status
  }

  public startAudioTest(mediaOptions: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.startTest(TestCase.audio, mediaOptions, (results: any) => {
        if(this.testSuite.running){
         this.statusFnc(results)
        } else {
         resolve(results)
        }
        
      }, (err: any) => reject(err))
   })
  }

  public startPermissionTest(mediaOptions :any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.startTest(TestCase.audio, mediaOptions, (results: any) => {
        if(this.testSuite.running){
         this.statusFnc(results)
        } else {
         resolve(results)
        }
        
      }, (err: any) => reject(err))
   })
  }

  public running(): boolean {
    return this.testSuite.running
  }

  private startTest(testCase: TestCase, mediaOptions: any, testCompleted: Function, err: Function) {
    if (!navigator.mediaDevices) {
      this.video = false;
      this.audio = false;
    }


    if (this.audio) {

      // Microphone Permission Test
      if(testCase === TestCase.microphonePermission) {
        const microphonePermissionsTest = new PermissionsTest(false, false, mediaOptions);
        microphonePermissionsTest.promise.then(testCompleted.bind(null, microphonePermissionsTest, true), testCompleted.bind(null, microphonePermissionsTest, false));
        this.testSuite.addTest(microphonePermissionsTest);
      }
      
      // Test Audio from Microphone
      if(testCase === TestCase.audio){
        const audioTest = new AudioTest(mediaOptions);
        audioTest.promise.then(testCompleted.bind(null, audioTest, true), err.bind(null, audioTest, false));
        this.testSuite.addTest(audioTest);
      }
    }

    this.testSuite.start().then(function (results) {
      testCompleted(results)
    }, function (errors) {
      err(errors)
    }).catch(e => err(e))
  }
}