import Test from '../utils/Test';
import LocalMedia from 'localmedia';
import { APPSTATES } from "../../constants/states";

/**
 * Class to test basic audio
 */

export default class AudioTest extends Test {

  private volumeTimeout: number;
  private localMedia: LocalMedia;
  private status: number;

  public static AUDIO_TEST_NAME = 'Audio Test';

  constructor(args: any) {
    super(args);
    this.name = AudioTest.AUDIO_TEST_NAME;
    this.volumeTimeout = this.options.volumeTimeout || 20000;
    this.localMedia = new LocalMedia({ detectSpeakingEvents: true });
    this.status = -1
  }

  /**
   * Start the test
   */
  public start(): Promise<any> {
    super.start(); // tslint:disable-line
    console.log('start');

    const volumeCheckFailure = window.setTimeout(() => {
      this.logger.error('No change in mic volume');
      return this.reject(new Error('audio timeout'));
    }, this.volumeTimeout);

    // Updated line
    const options = { ...this.options, video: false };

    try {
      this.localMedia.start(options, (err) => {
        if (err) {
          this.logger.error('Audio Local media start failed');
          return this.reject(err);
        } else {
          this.logger.log('Audio Local media started');
        }
      });
    } catch (e) {
      console.log("Error iOS12 not supported")
    }

    this.localMedia.on('volumeChange', (volume: number) => {
      if (document.body.dataset && document.body.dataset.appstatus && typeof Number(document.body.dataset.appstatus)) {
        this.status = Number(document.body.dataset.appstatus);
      }

      window.clearTimeout(volumeCheckFailure);

      if (this.status !== APPSTATES.MICCHECK) {
        return this.resolve();
      }
    });

    this.localMedia.on('localStream', (stream: MediaStream) => {
      if (stream.getAudioTracks().length) {
        let audioTrack: MediaStreamTrack = stream.getAudioTracks()[0];
        if (audioTrack) {
          this.logger.log('Audio stream passed');
        } else {
          this.logger.error('Audio stream failed');
          return this.reject(new Error('no audio tracks available'));
        }
      }
    });
    return this.promise;
  }

  /**
   * Tear down the test
   */
  public destroy(): void {
    super.destroy();
    this.localMedia.stop();
  }
}
