import LocalAuth from "../helpers/LocalAuth";

/**
 * Hook for LocalAuth
 * @returns { authenticate: function, stored: boolean, logout: function } Hook for LocalAuth
 */
const useLocalAuth = () => {
  /**
   * Delete all Information stored in Cookies
   */
  function logout(): void {
    LocalAuth.cleanUp();
    window.location.reload();
  }

  /**
   * Save Information to Cookie
   * @param firstName string First Name of User
   * @param lastName string Last Name of User
   * @param companyName string Company Name
   */
  function authenticate(name: string, job: string, occupation: string, interests: string, country: string, companyName: string, email: string): void {
    const localAuth: LocalAuth = new LocalAuth(name, job, occupation, interests, country, companyName, email);
    localAuth.save();
  }

  /**
   * Local LocalAuth from Cookie and return as an Instance
   * @returns LocalAuth Instance
   */
  function storedObject(): Promise<LocalAuth | undefined> {
    return LocalAuth.load();
  }

  return { authenticate, isAuthenticated: LocalAuth.valid(), logout, storedObject };
};

export default useLocalAuth;
