import React from 'react'

export default class IconCheck extends React.Component {

  render() {
    return (
      <>

      {/* <svg style={Styles.svg} viewBox="0 0 22 16">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        </g>
      </svg> */}

      <svg style={Styles.svg} viewBox="0 0 24 24">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g stroke="#E2E2E2" strokeWidth="1" >
            <rect className="rectangle" x="1.5" y="1.5" width="21" height="21" rx="5" />
          </g>
          <g transform="scale(0.7)" fill="#B17762" className="origin-center">
            <polygon points="20.6465116 4 8.7255814 15.5061728 3.35348837 10.3209877 1 12.5925926 7.54883721 18.9135802 8.7255814 20 9.90232558 18.9135802 23 6.27160494" />
          </g>
        </g>
      </svg>
      </>

    )
  }
}

const Styles = {
  svg: {
    width: '28px',
    height: '28px',
  }
}
