import React from "react";
import { FormGroup } from "semantic-ui-react";
import Auth0LoginButton from "./components/Auth0LoginButton";


interface Auth0FormProps {
  updateSetIsRegisteredView?: (state: boolean) => void;
}

const Auth0Form: React.FC<Auth0FormProps> = (props: Auth0FormProps) => {
  const form = (props: Auth0FormProps) => {
    return (
      <div className="w-full">
        <form>
          <FormGroup className="flex justify-center">
            <Auth0LoginButton />
          </FormGroup>
        </form>
      </div>
    );
  };

  return <>{form({...props})}</>;
};

export default Auth0Form;
