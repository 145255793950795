// import { useEffect } from "react";
import { useState } from "react";
import LocalMedia from "localmedia";
import { WebRTCTroubleshooter } from "../WebRTCTroubleshooter";
import { AUDIOTESTSTATES } from "../constants/states";
import { audioTestService } from "../services/audioTestService";
// const { first } = require('rxjs/operators');

export const useWebRtcCheck = (deviceId: string) => {
  const options = Object.assign({}, {}, { audio: true, video: false });
  const localMedia = new LocalMedia({ detectSpeakingEvents: true });

  const [permissionTestRunning, setPermissionTestRunning] = useState<boolean>(false);
  const [results, setResults] = useState([]);
  const [errors, setErrors] = useState([]);
  const [status, setStatus] = useState<any>();
  const [volume, setVolume] = useState<number>(0);

  // const [audioSource, setAudioSource] = useState<string>(deviceId);
  // const [audioTestStatus, setAudioTestStatus] = useState<number>();
  
  // const [options, setOptions] = useState<object>(
  //   Object.assign({}, {}, { audio: true, video: false })
  //   // Object.assign({}, {}, { audio: { deviceId: audioSource ? { exact: audioSource } : undefined }, video: false })
  // );

  // useEffect(() => {
  //   audioTestService.getAudioTestStatus().subscribe((n: any) => {
  //     setAudioTestStatus(n);
  //   });
  // }, []);

  //   setAudioSource(deviceId);
  //   setOptions(Object.assign({}, {}, { audio: { deviceId: deviceId ? { exact: deviceId } : undefined }, video: false }));
  // }, [deviceId]);


  localMedia.on("volumeChange", (volume: number) => {
    setVolume(volume);
  });

  const test = new WebRTCTroubleshooter((status: any) => {
    setStatus(status);
  });

  const startMicrophonePermissionTest = () => {
    setPermissionTestRunning(true);
    test
      .startPermissionTest(options)
      .then((results) => {
        setPermissionTestRunning(false);
        setResults(results);
      })
      .catch((err) => {
        setPermissionTestRunning(false);
        setErrors(err);
      });
  }

  const startAudioTest = (selected :any) => {
    audioTestService.updateAudioTestStatus(AUDIOTESTSTATES.RUNNING);
    const modifiedOptions = {
      ...options,
      audio: selected ? { deviceId: { exact: deviceId } } : options.audio,
    };
    console.log("options", modifiedOptions, "selected", selected);
    localMedia.start(modifiedOptions, () => {});
    console.log('start audio test');
    test.startAudioTest(modifiedOptions).then((results) => {
        // audioTestService.getAudioTestStatus().pipe(first).subscribe(value => console.log(value));
        audioTestService.updateAudioTestStatus(AUDIOTESTSTATES.COMPLETE);
        setResults(results);
        localMedia.stop();
      })
      .catch((err) => {
        audioTestService.updateAudioTestStatus(AUDIOTESTSTATES.FAILURE);
        setErrors(err);
        localMedia.stop();
      });
  }

  const stopAudioTest = () => {
    audioTestService.updateAudioTestStatus(AUDIOTESTSTATES.STOPPED);
  }

  return { startMicrophonePermissionTest, startAudioTest, stopAudioTest, permissionTestRunning, results, errors, status, volume };
};
