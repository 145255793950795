export default class ConverveParticipant {
    private readonly _firstName: string
    private readonly _lastName: string
    private readonly _email: string
    private readonly _company: string


    constructor(participant: any){
        this._firstName = participant["first_name"]
        this._lastName = participant["last_name"]
        this._email = participant["email"]
        this._company = participant["company"]

    }

    static parseFromJson(json: string): ConverveParticipant {
        let parsed = JSON.parse(json)

        return new ConverveParticipant(parsed)
    }

    get firstName(): string {
        return this._firstName
    }

    get lastName(): string {
        return this._lastName
    }

    get email(): string {
        return this._email
    }

    get company(): string {
        return this._company
    }

    /**
     * Format Class to JSON String
     * @returns JSON string
     */
    toJson(): string {
        return JSON.stringify({
            "first_name": this._firstName,
            "last_name": this._lastName,
            "company": this._company,
            "email": this._email
        })    
    } 
}