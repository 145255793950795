import { InputEmitter, StreamerStatus } from "@pureweb/platform-sdk";

import { useEffect } from "react";
import { Subject } from "rxjs";
import { System } from "@pureweb/platform-sdk-react";

require("dotenv").config();

const VALUE_LISTENER = "systemExchange";

interface UnrealSystemExchangeContent {
  osname: string;
  os: string;
  platform: string;
  type: string;
  version: string;
}

export const useUnrealSystemExchange = (emitter: InputEmitter, streamerStatus: StreamerStatus, messageSubject: Subject<string>) => {
  useEffect(() => {
    const emitContent = (content: UnrealSystemExchangeContent) => {
      emitter.EmitUIInteraction(content);
    };

    /**
     * Check if everything is ready for emit
     * @param streamerStatus StreamerStatus
     * @param emitter Emitter
     * @returns canEmit Boolean
     */
    const canEmit = (streamerStatus: StreamerStatus, emitter: InputEmitter): boolean => {
      return !emitter || streamerStatus !== StreamerStatus.Connected;
    };

    if (canEmit(streamerStatus, emitter)) return;

    const info = System.Browser();
    console.log(info.os);

    const content: UnrealSystemExchangeContent = {
      osname: info.name,
      os: info.os || '',
      platform: navigator.platform,
      type: info.type,
      version: info.version,
    };

    const subscription = messageSubject.subscribe(
      (value: string) => {
        try {
          let json = JSON.parse(value);

          if (json.type === VALUE_LISTENER) {
            emitContent(content);
          }
        } catch (e) {}
      },
      (err: any) => {
        console.error(err);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [messageSubject, emitter, streamerStatus]);
};
