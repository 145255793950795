import { useEffect } from "react";
import { InputEmitter, StreamerStatus } from "@pureweb/platform-sdk";
import { Subject } from "rxjs";

// enum ControlState {
//   copy = 'copy',
//   paste = 'paste'
// }

/**
 * Copy Paste Hook
 */
const useCopyPasteIntegration = (emitter: InputEmitter, streamerStatus: StreamerStatus, messageSubject: Subject<string>) => {
  // const [state, setState] = useState<ControlState | undefined>()

  /**
   * CLPD_CP:
   * CLPD_SND:
   * CLPD_RCV:
   */

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      let charCode = String.fromCharCode(event.which).toLowerCase();
      if ((event.ctrlKey || event.metaKey) && charCode === "c") {
        console.log("CTRL+C Pressed");
        emitContent({ key: "CLPD_CP", value: "" });
      } else if ((event.ctrlKey || event.metaKey) && charCode === "v") {
        console.log("CTRL+V Pressed");

        navigator.clipboard
          .readText()
          .then((text) => {
            console.log("Send Copy Text to Unreal: ", text);
            emitContent({ key: "CLPD_SND", value: text });
          })
          .catch(console.error);
      }
    };

    document.addEventListener("keyup", handleKeyDown, true);

    return () => {
      document.removeEventListener("keyup", handleKeyDown, true);
    };
  }, [emitter]);

  useEffect(() => {
    if (cannotEmit(streamerStatus, emitter)) {
      return;
    }
    const subscription = messageSubject.subscribe(
      (value: string) => {
        try {
          let json = JSON.parse(value);

          if (json.CLPD_RCV) {
            console.log("Receive Text from Unreal: ", json.CLPD_RCV);
            navigator.clipboard.writeText(json.CLPD_RCV);
          }
        } catch (e) {
          console.error(e);
        }
      },
      (err: any) => {
        console.error(err);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [messageSubject, emitter, streamerStatus]);

  function emitContent(content: any) {
    emitter.EmitUIInteraction(content);
  }

  function cannotEmit(streamerStatus: StreamerStatus, emitter: InputEmitter): Boolean {
    return !emitter || streamerStatus !== StreamerStatus.Connected;
  }
};

export default useCopyPasteIntegration;
