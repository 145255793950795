import { useState } from "react";
import SupportedLanguage from "../helpers/SupportedLanguages";

const usePlatformLanguage = () => {
    const [language, setLanguage] = useState<SupportedLanguage>(SupportedLanguage.en)


    return { language, setLanguage }
}

export default usePlatformLanguage;