import Cookies, { CookieSetOptions } from "universal-cookie";
import queryString from 'query-string';

interface LocalAuthObjectInterface {
  name: string;
  occupation: string;
  job: string;
  country: string;
  interests: string;
  email: string;
  companyName: string;
}

/**
 * LocalAuth stores User Information in Cookie
 */
class LocalAuth {
  private _email: string;
  //private _firstName: string
  //private _lastName: string
  private _name: string;
  private _job: string;
  private _occupation: string;
  private _interests: string;
  private _country: string;
  private _companyName: string;
  private _cookies: Cookies = new Cookies();

  // Token is used to verify correcst URL
  static secureUrlToken: string = process.env.REACT_APP_LOCALAUTH_CODE || "e242b9942b657968b7ac7c181e8974975224e9e14fd356706575d66a171d006221acbf0f315af3301090";

  // Token is used as key for cookie
  static secret: string = "61f54bf62ec9f5dc07f52415da3e242b9942b657968b7ac7c181e8974975224e9e14fd356706575d66a171d006221acbf0f315af33010901198385a8924f4edf";

  AES256 = 32; // 256 Bits
  ONE_HOUR = 3600000;
  ONE_WEEK = this.ONE_HOUR * 24 * 7;

  private cookie_options: CookieSetOptions = {
    path: "/",
    secure: true,
    expires: new Date(Date.now() + this.ONE_WEEK),
  };

  /**
   * Load User Details from Cookie to LocalAuth Instance
   * @returns Promise<LocalAuth | undefined> from saved Cookie
   */
  static async load(): Promise<LocalAuth | undefined> {
    const object: LocalAuthObjectInterface | undefined = await this.loadFromCookie();
    if (!Boolean(object)) return undefined;

    return new LocalAuth(object!.name, object!.job, object!.occupation, object!.interests, object!.country, object!.companyName, object!.email);
  }

  /**
   * Load Data from Cookie to Object
   * @returns Promise<LocalAuthObjectInterface | undefined> Object
   */
  static async loadFromCookie(): Promise<LocalAuthObjectInterface | undefined> {
    const cookies = new Cookies();
    const json = cookies.get(LocalAuth.secret);

    if (json === undefined) return undefined;

    return json;
  }

  static valid(): boolean {
    const cookies: Cookies = new Cookies();
    return this.validCode() && Boolean(cookies.get<string>(LocalAuth.secret));
  }

  static validCode(): boolean {
    const params = queryString.parse(window.location.search);
    return params.code === this.secureUrlToken;
  }

  static cleanUp(): void {
    const cookies: Cookies = new Cookies();
    cookies.remove(LocalAuth.secret);
  }

  constructor(name: string, job: string, occupation: string, interests: string, country: string, companyName: string, email: string) {
    //this._firstName = firstName
    //this._lastName = lastName
    this._name = name;
    this._job = job;
    this._occupation = occupation;
    this._interests = interests;
    this._country = country;
    this._companyName = companyName;
    this._email = email;
  }

  get companyName(): string {
    return this._companyName;
  }

  get email(): string {
    return this._email;
  }

  get name(): string {
    return this._name;
  }

  get job(): string {
    return this._job;
  }

  get occupation(): string {
    return this._occupation;
  }

  get country(): string {
    return this._country;
  }

  get interests(): string {
    return this._interests;
  }

  /**
   * Save current instance to Cookie
   */
  save(): void {
    this._cookies.set(LocalAuth.secret, this.toJson(), this.cookie_options);
  }

  /**
   * LocalAuth to String Object JSON
   * @returns object as string converted
   */
  toJson(): string {
    return JSON.stringify({
      name: this._name,
      job: this._job,
      occupation: this._occupation,
      interests: this._interests,
      country: this._country,
      email: this._email,
      companyName: this._companyName,
    });
  }
}

export default LocalAuth;
