/**
 * This reference template is designed to showcase the elements used to construct your own
 * application.
 * 
 * When developing take care to:
 *  - Retain user interaction to begin audio.
 *  - Understand video sizing and mobile screen orientation.

 * See attached documentation for reference. Contact support@pureweb.com with any questions.
 * 
 *
 * Copyright (C) PureWeb 2020
 */

import {
  ModelDefinition,
  PlatformNext,
  UndefinedModelDefinition,
  DefaultStreamerOptions,
  StreamerStatus,
  Resolution,
  streamResolutionConfiguration,
  StreamerAudioConfiguration
} from '@pureweb/platform-sdk';

import {
  useStreamer,
  useLaunchRequest,
  LaunchRequestOptions,
  VideoStream,
  System
} from '@pureweb/platform-sdk-react';

import logger from "./Log";
import { useEffect, useState } from "react";
import useAsyncEffect from "use-async-effect";
import { AudioView } from "./AudioView";
import { EmbeddedView } from "./EmbeddedView";
import Navbar from "./components/Navbar";
import { useClientOptions } from "./hooks/useClientOptions";
import useUnrealMessageTabListener from "./hooks/useUnrealMessageTabListener";
import ClientOptions from "./ClientOptions";
import useSendAudioFrames from "./hooks/useSendAudioFrames";
import AuthenticationView from "./AuthenticationView";
import useClientTranslation from "./hooks/useClientTranslation";
import useAuthenticationSwitch, { AuthenticationState } from "./hooks/useAuthenticationSwitch";
import { useUnrealTokenExchange } from "./hooks/useUnrealTokenExchange";
import { useUnrealLogoutListener } from "./hooks/useUnrealLogoutListener";
import usePlatformLanguage from "./hooks/usePlatformLanguage";
import { useUnrealAuth0Exchange } from "./hooks/useUnrealAuth0Exchange";
import { useUnrealConverveParticipantExchange } from "./hooks/useUnrealConverveParticipantExchange";
import { useIsValidDevice } from "./hooks/useIsValidDevice";
import { useUnrealSystemExchange } from "./hooks/useUnrealSystemExchange";
import { useUnrealAzureAuthExchange } from "./hooks/useUnrealAzureAuthExchange";
import awsconfig from "./aws-exports";
import { useUnrealPingTokenExchange } from "./hooks/useUnrealPingTokenExchange";
import { useUnrealLocalAuthExchange } from "./hooks/useUnrealLocalAuthExchange";
import useCopyPasteIntegration from "./hooks/useCopyPasteIntegration";
import { useIsIphone } from "./hooks/useIsIphone";
//import TagManager from "react-gtm-module";
import LocalAuth from "./helpers/LocalAuth";
import useLocalAuth from "./hooks/useLocalAuth";
import { APPSTATES, AUDIOTESTSTATES, MICPERMISSIONSTATES } from "./constants/states";
import { audioTestService } from "./services/audioTestService";
import { appStatusService } from "./services/appStatusService";
import { micPermissionsService } from "./services/micPermissionsService";
import { BrowserRouter as Router } from "react-router-dom";
import Auth0ProviderWithHistory from "./auth0-provider-with-history";
import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { bool } from 'prop-types';

const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID || '',
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_TENANT_ID}`,
    redirectUri: window.location.origin 
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true, // recommended for IE/Edge
}
};

const pca = new PublicClientApplication(msalConfig);

var sendEvent = true;

/**
 * Authentication Switch
 * Choose between:
 *  - GC:       For Gray Convention GmbH Token Authentication
 *  - AKAMAI:   For Akamai Redirection Authentication
 *  - NONE:     No Authentication
 */
const authenticationState: AuthenticationState = AuthenticationState.AZURE;

// Initialize audio.
// load() must be called from a user interaction, especially to retain iOS audio
// this can be 'mouseup', 'touchend' or 'keypress'
// Pass the audioStream created from useStreamer as the srcObject to play game audio.
const audio = new Audio();
audio.autoplay = true;
audio.volume = 1;

// Initialize platform reference
const platform = new PlatformNext();

// types and interfaces
type BeforeunloadHandler = (evt: BeforeUnloadEvent) => void;
interface AppProps {
  clientOptions: ClientOptions;
}

const App: React.FC<AppProps> = (props: AppProps) => {
  /* Setttings */
  const { clientOptions } = props;
  const streamerOptions = DefaultStreamerOptions;
  const launchRequestOptions: LaunchRequestOptions = {
    // regionOverride: clientOptions.regionOverride,
    // virtualizationProviderOverride: clientOptions.virtualizationProviderOverride
  };
  streamerOptions.forceRelay = clientOptions.ForceRelay;
  const opts: AddEventListenerOptions & EventListenerOptions = {
    passive: true,
  };

  /* platform init */
  platform.initialize({ endpoint: clientOptions.Endpoint || "https://api.pureweb.io" });

  /**
   * Update selected audio input device / microphone
   */
  const setSelectedMicrophone = (selected: string) => {
    setMicrophone(selected);
  };

  /* States */
  /* appStatus and preventQueueForTesting can be adjusted for testing purposes. isLocalhost is set to prevent this settings from being accidentally applied in dev or production environment */
  const isLocalhost = window.location.hostname === "localhost";
  const testAppStatus = APPSTATES.LOGIN;
  const testPreventQueuingForTesting = false;

  const [appStatus, setAppStatus] = useState<number>(isLocalhost ? testAppStatus : APPSTATES.LOGIN);
  const [micPermissions, setMicPermissions] = useState<number>(MICPERMISSIONSTATES.UNSET);
  const [audioTestStatus, setAudioTestStatus] = useState<number>(AUDIOTESTSTATES.NOT_STARTED);
  const [microphone, setMicrophone] = useState<string>("");
  const preventQueuingForTesting = (isLocalhost ? testPreventQueuingForTesting : false);
  const [modelDefinition, setModelDefinition] = useState(new UndefinedModelDefinition());
  // const [platformError, setPlatformError] = useState<any>();
  const [availableModels, setAvailableModels] = useState<ModelDefinition[]>();
  const [scrolledDown, setScrolledDown] = useState<boolean>(false); 
  const [launched, setLaunched] = useState<boolean>(false);
  const [localAuth, setLocalAuth] = useState<LocalAuth | undefined>();
  const [shouldAuthenticate, setShouldAuthenticate] = useState(false);
  const triggerAzureAuthentication = () => {
    setShouldAuthenticate(true);
  };



  /* Call hooks */
  const isIphone = useIsIphone();
  const { isAuthenticated, storedObject } = useLocalAuth();
  const { language } = usePlatformLanguage();
  const { translation } = useClientTranslation(language);
  const [status, launchRequest, queueLaunchRequest] = useLaunchRequest(platform, modelDefinition, launchRequestOptions);
  const [streamerStatus, emitter, videoStream, audioStream, messageSubject] = useStreamer(platform, launchRequest, streamerOptions);
  const { authenticated, check, bearer, logout, status: responseStatus } = useAuthenticationSwitch(authenticationState);
  useCopyPasteIntegration(emitter, streamerStatus, messageSubject);
  // Unreal hooks
  useUnrealAuth0Exchange(emitter, streamerStatus, messageSubject, authenticationState);
  useUnrealConverveParticipantExchange(emitter, streamerStatus, messageSubject);
  useUnrealLocalAuthExchange(emitter, streamerStatus, messageSubject);
  useUnrealLogoutListener(emitter, streamerStatus, messageSubject, () => logout());
  useUnrealMessageTabListener(messageSubject);
  useUnrealPingTokenExchange(emitter, streamerStatus, messageSubject);
  useUnrealSystemExchange(emitter, streamerStatus, messageSubject);
  useUnrealTokenExchange(emitter, streamerStatus, messageSubject, bearer);
  useUnrealAzureAuthExchange(emitter, streamerStatus, messageSubject);
    useSendAudioFrames({ emitter, streamerStatus, microphone });
 
  // 

  /* Functions start */
  /**
   * Scroll function that toggles state scrolledDown if scroll position is at the top or not
   */
  const onScroll = () => {
    const position = window.pageYOffset;

    if (position < 50) {
      setScrolledDown(false);
    } else {
      setScrolledDown(true);
    }
  };

  /**
   * Update app status
   */
  // const updateAppStatus = (newStatus: number) => {
  //   appStatusService.updateAppStatus(newStatus);
  // };

  /**
   * Load audio function
   */
  const loadAudio = () => {
    audio.load();
  };

  if (audioStream) {
    audio.srcObject = audioStream;
  }

  /**
   * Launch function
   */
  const launch = async () => {
    if (clientOptions.LaunchType !== "local") {
      try {
        await queueLaunchRequest();
      } catch (e: any) {
        console.log("platform error");

        // @ts-ignore
        // setPlatformError(e);

        TagManager.dataLayer({ dataLayer: { event: "MetaverseConnected", Info: "false" } });
      }
    }
  };

  useEffect(() => {
    if (appStatus === APPSTATES.LOADING && modelDefinition.hasOwnProperty("id") && !launched) {
      launch();
      setLaunched(true);
    }
  }, [appStatus, launched, modelDefinition]);
  /* Functions end */

  /* Effect hooks start */
  /**
   * set global app status and microphone permission status on change
   */
  useEffect(() => {
    audioTestService.getAudioTestStatus().subscribe((n: any) => {
      setAudioTestStatus(n);
    });

    appStatusService.getAppStatus().subscribe((n: any) => {
      setAppStatus(n);
      if (document.body.dataset && document.body.dataset.appstatus) {
        document.body.dataset.appstatus = n;
      }
    });

    micPermissionsService.getMicPermissions().subscribe((n: any) => {
      setMicPermissions(n);
    });
  }, []);

  useEffect(() => {
    logger.info({
      "app status": APPSTATES[appStatus],
      "audio test status": AUDIOTESTSTATES[audioTestStatus],
      "mic permissions status": MICPERMISSIONSTATES[micPermissions],
    });

    if (
      (appStatus === APPSTATES.MICCHECKCOMPLETE && (audioTestStatus === AUDIOTESTSTATES.COMPLETE || audioTestStatus === AUDIOTESTSTATES.WITHOUT_MICROPHONE)) ||
      audioTestStatus === AUDIOTESTSTATES.FAILURE
    ) {
      setTimeout(() => {
        appStatusService.updateAppStatus(APPSTATES.LOADING);
      }, 50);
    }
  }, [appStatus, audioTestStatus, micPermissions]);

  /**
   * Connect to pureweb platform, pass settings and invoke getModels function
   */
  useAsyncEffect(async () => {
    if (availableModels) {
      return;
    }

    if (clientOptions.ProjectId && !preventQueuingForTesting) {
      logger.info("Initializing available models: " + clientOptions.ProjectId);
      try {
        await platform.useAnonymousCredentials(clientOptions.ProjectId, clientOptions.EnvironmentId);
        await platform.connect();
        logger.info("Agent Connected: " + platform.agent.id);
        streamerOptions.iceServers = platform.agent.serviceCredentials.iceServers as RTCIceServer[];
        streamerOptions.forceRelay = clientOptions.ForceRelay;

        const streamerAudio: StreamerAudioConfiguration = {
          maxAverageBitrateKbps: 320000,
          stereo: 1,
          useMic: false,
          micAudioOptions: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
            channelCount: 1,
            latency: 0,
            sampleRate: 48000,
            sampleSize: 16,
            volume: 1
          }
        };

        const models = await platform.getModels();
        setAvailableModels(models);
        logger.debug("Available models", models);
      } catch (err: any) {
        // @ts-ignore
        alert(err);
        // setPlatformError(err);
        logger.error(err);
      }
    }
  }, [appStatus, clientOptions, isIphone]);

  /**
   * Check for available pureweb models and, if available, set the one that is specified in clientOptions by comparing modelId and version
   */
  useEffect(() => {
    if (availableModels?.length) {
      const selectedModels = availableModels.filter(function (model: ModelDefinition): boolean {
        if (clientOptions.ModelId === model.id) {
          // If there is a version specified and we encounter it
          if (clientOptions.Version && clientOptions.Version === model.version) {
            return true;
          }
          // If there is no version specified and we find the primary version
          if (!clientOptions.Version && model.active) {
            return true;
          }
        }
        return false;
      });
      if (selectedModels?.length) {
        logger.debug("Model definition", selectedModels[0]);
        setModelDefinition(selectedModels[0]);
      }
    }
  }, [availableModels, clientOptions.ModelId, clientOptions.Version]);

  // Disconnect platform if stream connection failed
  useEffect(() => {
    if (streamerStatus === StreamerStatus.Failed) {
      platform.disconnect();
    }

    // Add beforeunload event listener
    /**
     * Open dialog if user closes tab to prevent accidential closing
     * @param _e event
     * @returns
   
    const unloadHandler: BeforeunloadHandler = (_e) => {
      if (streamerStatus === "Connected") {
        _e.preventDefault();
        _e.returnValue = "Are you sure you want to leave?";
      }
    };

    window.addEventListener("beforeunload", unloadHandler);
    return () => window.removeEventListener("beforeunload", unloadHandler);
     */
  }, [streamerStatus]);

  // Add scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", onScroll, opts);
    return () => {
      window.removeEventListener("scroll", onScroll, opts);
    };
  });

  // Log status messages
  useEffect(() => {
    logger.info("Status", status, streamerStatus);
  }, [status, streamerStatus]);

  // Subscribe to game messages
  useEffect(() => {
    const subscription = messageSubject.subscribe(
      (value: string) => {
        logger.info("Message: " + value);
      },
      (err) => {
        logger.error(err);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [messageSubject]);

  useAsyncEffect(async () => {
    if (!isAuthenticated) return;

    let localAuth: LocalAuth | undefined = await storedObject();

    if (!localAuth) console.error("Cookie is not set!");

    setLocalAuth(localAuth!);
  }, [isAuthenticated]);

  /* Effect hooks end */

  // Generate notifications for possible states of StreamerStatus "Disconnected", "Failed", "Withdrawn", "Connected"
  if (streamerStatus === StreamerStatus.Disconnected) {
    console.log("disconnected from metaverse");
    return (
      <div className="flex h-screen text-center items-center justify-center ">
        {/* @ts-ignore */}
        <h2>{translation.App.DisconnectedFromStream}</h2>
      </div>
    );
  }

  if (streamerStatus === StreamerStatus.Failed) {
    console.log("disconnected from metaverse - streamer status failed");
    return (
      <div className="flex h-screen text-center items-center justify-center ">
        {/** @ts-ignore */}
        <h2 dangerouslySetInnerHTML={{ __html: translation.App.Failed }} />
      </div>
    );
  }

  if (streamerStatus === StreamerStatus.Withdrawn) {
    console.log("disconnected from metaverse - streamer status withdrawn");
    return (
      <div className="flex h-screen text-center items-center justify-center">
        <h2 dangerouslySetInnerHTML={{ __html: translation.App.Withdrawn }} />
      </div>
    );
  }

  if (streamerStatus === StreamerStatus.Connected && sendEvent) {
    console.log("connected to metaverse");
    sendEvent = false;
  }

  // Generate notification in case of a possible platform error
  // if (platformError) {
  //  return (
  //  <div className="flex h-screen text-center items-center justify-center isvalidde">
  //<h2 dangerouslySetInnerHTML={{ __html: translation.App.ConnectionError }}></h2>
  // </div>
  //  );
  // }

  let view;
  switch (appStatus) {
    case APPSTATES.LOGIN:
      view = (
        <AuthenticationView
          check={check}
          authenticationState={authenticationState}
          status={responseStatus!}
          emitter={emitter}
          streamerStatus={streamerStatus}
          messageSubject={messageSubject}
          isAuthenticated={authenticated}
          localAuth={localAuth}
          logout={logout}
          shouldAuthenticate={shouldAuthenticate}
          triggerAzureAuthentication={triggerAzureAuthentication}
        />
      );
      break;
    case APPSTATES.MICCHECK:
    case APPSTATES.MICCHECKCOMPLETE:
      view = (
        <AudioView setSelectedMicrophone={setSelectedMicrophone} appStatus={appStatus} loadAudio={loadAudio} isAuthenticated={authenticated} logout={logout} />
      );
      break;
    case APPSTATES.LOADING:
      view = (
        <EmbeddedView
          VideoStream={videoStream}
          StreamerStatus={streamerStatus as StreamerStatus}
          LaunchRequestStatus={status}
          //SignallingConnection={signallingConnection as ISignallingConnection}
          InputEmitter={emitter}
          launch={launch}
          UseNativeTouchEvents={clientOptions.UseNativeTouchEvents!}
          UsePointerLock={clientOptions.UsePointerLock}
          PointerLockRelease={clientOptions.PointerLockRelease!}
          Resolution={clientOptions.Resolution!}
          MessageSubject={messageSubject}
          ScrolledDown={scrolledDown}
        />
      );
      break;
    default:
      view = <></>;
  }

  return (
    <>
      {view}
      <Navbar isAuthenticated={authenticated} />
    </>
  );
};

const ConditionalWrapper = ({ condition, wrapper, children }: { condition: boolean; wrapper: any; children: JSX.Element }) =>
  condition ? wrapper(children) : children;

  const AppWrapper: React.FC = () => {
    const clientOptions = useClientOptions();
    const { language } = usePlatformLanguage();
    const { translation } = useClientTranslation(language);
    const [valid] = useIsValidDevice();
  
    return valid ? (
      clientOptions ? (
        <Router>
          <ConditionalWrapper
            condition={(authenticationState as AuthenticationState) === AuthenticationState.AUTH0}
            wrapper={(children: JSX.Element) => <Auth0ProviderWithHistory>{children}</Auth0ProviderWithHistory>}
          >
            <ConditionalWrapper
              condition={(authenticationState as AuthenticationState) === AuthenticationState.AZURE}
              wrapper={(children: JSX.Element) => <MsalProvider instance={pca}>{children}</MsalProvider>}
            >
              <App clientOptions={clientOptions!} />
            </ConditionalWrapper>
          </ConditionalWrapper>
        </Router>
      ) : (
        <div />
      )
    ) : (
      <div className="ui red segment center aligned basic">
        {/* @ts-ignore */}
        <h2 className="header" style={{color:"red"}} dangerouslySetInnerHTML={{ __html: translation.AppWrapper.NotSupported.replace("$1", System.Browser().name) }} />
      </div>
    );
  };
  
  export default AppWrapper;
  
