// import { ParsedQuery } from "query-string";
import React, { useState } from "react";
import { Form, FormGroup } from "semantic-ui-react";
import countries from "./countries.json";
import LocalAuth from "./helpers/LocalAuth";
import InvalidCodeMessage from "./components/InvalidCodeMessage";
import useClientTranslation from "./hooks/useClientTranslation";
import usePlatformLanguage from "./hooks/usePlatformLanguage";

interface LocalAuthFormProps {
  code?: string;
  onSave: (name: string, job: string, occupation: string, interests: string, country: string, companyName: string, email: string) => void;
  updateSetIsRegisteredView?: (state: boolean) => void;
}

const LocalAuthForm: React.FC<LocalAuthFormProps> = (props: LocalAuthFormProps) => {
  // const [firstName, setFirstName] = useState("a");
  // const [lastName, setLastName] = useState("a");
  const [name, setName] = useState("");
  const [job, setJob] = useState("");
  const [occupation, setOccupation] = useState("");
  const [country, setCountry] = useState("");
  const [interests, setInterests] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  // const params = useQuery();
  const { language } = usePlatformLanguage();
  const { translation } = useClientTranslation(language);
  const occupations = [
    { text: translation.AuthenticationView.localAuth.form.participant, value: "Participant" },
    { text: translation.AuthenticationView.localAuth.form.host, value: "Host" },
  ];

  /**
   * On Button click save => callback(onSave) is called to store information in cookie
   * @returns void
   */
  function save(): void {
    props.onSave(name, job, occupation, interests, country, companyName, email);
  }

  /**
   * @private
   * @param params QueryParams from URL
   * @returns validToken: boolean if Params Token is same as LocalAuth SecureURLToken
   */
  // function validToken(params: ParsedQuery<string>, code: string | undefined): boolean {
  //   return Boolean(params.code) || Boolean(code);
  // }

  const form = (props: LocalAuthFormProps) => {
    return (
      <div className="w-full">
        <div className="block md:flex justify-between space-x-0 md:space-x-20">
          {/* <input onClick={() => typeof props.updateSetIsRegisteredView !== 'undefined' && props.updateSetIsRegisteredView(false)} type="button" className="block md:inline-block btn btn-brown cursor-pointer" value={translation.AuthenticationView.localAuth.form.backButton} /> */}
          <a className="btn btn-secondary block text-center" href="https://workshop.bizzlogic.com/tutorial.jpg" target="_blank" rel="noreferrer">
            {translation.AuthenticationView.localAuth.form.profileguide}
          </a>
        </div>
        <form onSubmit={save}>
          <FormGroup>
            <input
              className="btn"
              placeholder={translation.AuthenticationView.localAuth.form.email + " *"}
              required
              id="email"
              type="email"
              tabIndex={0}
              onChange={(e) => setEmail(e.target.value)}
            />

            <input
              className="btn"
              placeholder={translation.AuthenticationView.localAuth.form.name + " *"}
              required
              maxLength={42}
              id="name"
              type="text"
              onChange={(e) => setName(e.target.value)}
            />

            <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-20">
              <input
                className="btn flex-1"
                placeholder={translation.AuthenticationView.localAuth.form.job + " *"}
                required
                maxLength={28}
                id="job"
                type="text"
                onChange={(e) => setJob(e.target.value)}
              />

              <input
                className="btn flex-1"
                placeholder={translation.AuthenticationView.localAuth.form.companyName + " *"}
                required
                maxLength={60}
                id="companyName"
                type="text"
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>

            <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-20">
              <Form.Select
                onChange={(_t, data) => setCountry(`${data.value}` || "")}
                options={countries}
                value={country}
                placeholder={translation.AuthenticationView.localAuth.form.country}
              />
             
            </div>

            <textarea
              className="btn"
              maxLength={120}
              onChange={(event) => setInterests(event.target.value)}
              placeholder={translation.AuthenticationView.localAuth.form.interests}
            />
          </FormGroup>

          <button className="btn btn-brown rounded focus:outline-brown-narrow mb-0" type="submit">
            {translation.AuthenticationView.localAuth.form.continueButton}
          </button>

          <p style={{fontSize: "9px"}}>* This information will only be stored locally and shared with other participants that are present</p>
        </form>
      </div>
    );
  };

  return <>{LocalAuth.validCode() ? form({ ...props }) : <InvalidCodeMessage />}</>;
};

export default LocalAuthForm;
