import { useState, useEffect } from 'react';
import { InteractionStatus, EventType, InteractionType } from '@azure/msal-browser';
import { useMsal, useIsAuthenticated, useMsalAuthentication } from '@azure/msal-react';



export const useAzureAuthentication = (enabled) => {
  const { instance, inProgress } = useMsal();
  const isAzureAuthenticated = useIsAuthenticated();
  const [azureUserClaims, setAzureUserClaims] = useState<{ oid: string, name: string, preferred_username: string, role: string } | null>(null);
  const [loadingAzureClaims, setLoadingAzureClaims] = useState(true);

  // Helper function to decode JWT:
  function decodeJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  // useEffect(() => {
  //   if (!isAzureAuthenticated && enabled && inProgress === InteractionStatus.None) {
  //     console.log("azure redirect");
  //     instance.loginRedirect().catch((e) => {
  //       console.error(e);
  //     });
  //   }
  // }, [isAzureAuthenticated, enabled, inProgress, instance]);
  let isEventListenerAdded = false;

  useEffect(() => {
    if (!isEventListenerAdded) {
    const callbackId = instance.addEventCallback((event) => {
      console.log("Azure Event:", event);
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const idToken = event.payload.idToken;
        const decodedToken = decodeJwt(idToken);
        const rolesArray = decodedToken.roles;
        const lastRole = rolesArray && rolesArray.length > 0 ? rolesArray[rolesArray.length - 1] : '';
        console.log("azure account", decodedToken );
          setAzureUserClaims({
            oid: decodedToken.oid,
            name: decodedToken.name,
            preferred_username: decodedToken.preferred_username,
            role: lastRole,
          });
        setLoadingAzureClaims(false);
      } else if (event.eventType === EventType.LOGIN_FAILURE) {
        setLoadingAzureClaims(false);
      }
    });
    isEventListenerAdded = true;
 return () => {
  if(callbackId)
      instance.removeEventCallback(callbackId);
      isEventListenerAdded = false;
    };
  }
}, [instance]);

  useEffect(() => {
    if (isAzureAuthenticated) {
      const currentAccounts = instance.getAllAccounts();
      if (currentAccounts.length > 0) {
        const accountInfo = currentAccounts[0];
        console.log("azure account 2", accountInfo);
        if (accountInfo.idTokenClaims) {
          const rolesArray = accountInfo.idTokenClaims.roles;
          const lastRole = rolesArray && rolesArray.length > 0 ? rolesArray[rolesArray.length - 1] : '';
        
          const userClaims = {
            oid: accountInfo.idTokenClaims.oid ?? '',
            name: accountInfo.idTokenClaims.name ?? '',
            preferred_username: accountInfo.idTokenClaims.preferred_username ?? '',
            role: lastRole
          };
          setAzureUserClaims(userClaims);
          console.log("azure user claims2 ", userClaims);

        } else {
          // Possibly handle the case where idTokenClaims is undefined
        }
        setLoadingAzureClaims(false);
      }
    }
  }, [isAzureAuthenticated, instance]);

  const logoutAzure = () => {
    instance.logout();
  };

  return {
    isAuthenticated: isAzureAuthenticated,
    azureUserClaims,
    loadingAzureClaims,
    logoutAzure,
  };
};
