// import useAsyncEffect from "use-async-effect";
import { useAuth0, LogoutOptions } from '@auth0/auth0-react';

export const useAuth0Authentication = (enabled: boolean) => {
    const { isAuthenticated, isLoading, logout } = useAuth0();

  // useAsyncEffect(async () => {
  //   if(!enabled || isAuthenticated) return
  // }, [])

  return { isAuthenticated: isAuthenticated, loading: isLoading, logout: () => logout({ returnTo: window.location.origin, client_id: process.env.REACT_APP_AUTH0_CLIENT_ID } as LogoutOptions)
}
}