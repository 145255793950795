 import {
   Resolution,
 } from '@pureweb/platform-sdk';

export default class ClientOptions {
    // Overridable connection options
    LaunchType?: string;
  
    // Launch queue configuration
    ProjectId?: string;
    ModelId?: string;
    Version?: string;
    EnvironmentId?: string;
    Endpoint?: string;
  
    // Overridable streamer options
    ForceRelay = false;
    UseNativeTouchEvents?: boolean;
    UsePointerLock = false;
    PointerLockRelease?: boolean;
    Resolution?: Resolution;

    Key?: string;
    Secret?: string;
    AppName?: string;
    PlatformUri?: string;
    regionOverride: string = '';
    // virtualizationProviderOverride: string = '';

    iframeUrl?: string = '';
  }