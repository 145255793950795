import { InputEmitter, StreamerStatus } from "@pureweb/platform-sdk";

import useAsyncEffect from "use-async-effect";
import { useEffect, useState } from "react";
import { Subject } from "rxjs";
import LocalAuth from "../helpers/LocalAuth";
import useLocalAuth from "./useLocalAuth";

require("dotenv").config();

const VALUE_LISTENER = "localAuthExchange";

// interface UnrealLocalAuthExchangeContent {
//     firstName: string,
//     lastName: string,
//     email: string,
//     companyName: string
// }

export const useUnrealLocalAuthExchange = (emitter: InputEmitter, streamerStatus: StreamerStatus, messageSubject: Subject<string>) => {
  const [localAuth, setLocalAuth] = useState<LocalAuth | undefined>();
  const { isAuthenticated, storedObject } = useLocalAuth();

  useAsyncEffect(async () => {
    if (!isAuthenticated) return;

    let localAuth: LocalAuth | undefined = await storedObject();
    if (!localAuth) console.error("Cookie is not set!");
    setLocalAuth(localAuth!);
  }, [isAuthenticated]);

  useEffect(() => {
    const emitContent = (content: any) => {
      emitter.EmitUIInteraction(content);
    }
  
    const canEmit = (streamerStatus: StreamerStatus, emitter: InputEmitter): Boolean => {
      return Boolean(localAuth) && (!emitter || streamerStatus !== StreamerStatus.Connected);
    }

    if (canEmit(streamerStatus, emitter)) return;

    const subscription = messageSubject.subscribe(
      (value: string) => {
        try {
          let json = JSON.parse(value);

          if (json.type === VALUE_LISTENER) {
            let metaverseType = process.env.REACT_APP_METAVERSE_TYPE as string;

            if (typeof localAuth !== "undefined") {
              emitContent({
                name: localAuth!.name,
                job: localAuth!.job,
                occupation: localAuth!.occupation,
                interests: localAuth!.interests,
                country: localAuth!.country,
                email: localAuth!.email,
                companyName: localAuth!.companyName,
                metaverseType: metaverseType,
              });
            }
          }
        } catch (e) {
          console.error(e);
        }
      },
      (err: any) => {
        console.error(err);
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, [messageSubject, emitter, streamerStatus, localAuth]);
};
